#home
{
    padding-top: 35vh;
    background-color: #000000;
    min-height: 100vh;
}

#home-title h1
{
    font-size: 100rem;
    line-height: 1em;
    letter-spacing: .2em;
    text-transform: uppercase;
    white-space: pre-wrap;
    margin-bottom: 0.2em;
}

#home-description
{
    white-space: pre-wrap;
}

#home-logos
{
    margin-top: 60rem;
    margin-bottom: -100px;
    max-width: 100%;
}

@media only screen and (max-width: 1000px) {
    #home-title h1
    {
        font-size: calc(9vw);
    }
}